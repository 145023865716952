import React from 'react';
import Modalx from './Modalx';

function Terms() {
  return (
    <main>
      <Modalx />
    </main>
  );
}

export default Terms;
