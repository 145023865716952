import React from 'react';
import HeroCom from './subcomponents/HeroCom';
import JoinCom from './subcomponents/JoinCom';
import EventCom from './subcomponents/EventCom';
import StoryCom from './subcomponents/StoryCom';
import GalleryCom from './subcomponents/GalleryCom';
import AboutCom from './subcomponents/AboutCom';

function Comunity() {
  return (
    <main className="text-blue-50/50 h-[100%] w-[100%] mx-auto overflow-x-hidden">
      <HeroCom />
      <JoinCom />
      <EventCom />
      <StoryCom />
      <GalleryCom />
      <AboutCom />
    </main>
  );
}

export default Comunity;
