import React from 'react';
import './css/Modalx.css';

function Modalx2() {
  return (
    <div className="box-div">
      <h3>TERMS AND CONDITIONS</h3>
      <p>
        ACCEPTANCE OF TERMS.
        <br />
        The terms and conditions below govern Shukran SACCO members’
        participation to the SACCO’s tip matching campaign (hereinafter referred
        to as “the campaign”)
        <br />
        Please read these terms and conditions carefully. Participation in the
        campaign constitutes acceptance of these terms and conditions.
      </p>
      <h3>DESCRIPTION OF CAMPAIGN</h3>
      <p>
        Shukran SACCO in partnership with Shukran Finance Platform Limited have
        launched a tip matching campaign applicable to Shukran SACCO members.
        <br />
        SACCO members shall be rewarded a shilling for every shilling tipped
        with an individual tip matching limit of KES 1000.
        <br />
        The maximum amount to be rewarded to the tip matching campaign
        participants in the duration of the campaign shall be KES 5000.
        <br />
        Any amount received under the campaign shall be paid to the member’s
        Shukran SACCO account and shall be notified when received to the
        account.
      </p>
      <h3>ELIGIBILITY</h3>
      <p>
        The tip matching campaign is eligible to SHUKRAN SACCO members who have
        fully paid their membership fees.
      </p>
      <h3>CAMPAIGN PERIOD</h3>
      <p>
        The campaign shall run from 20th September 2023 to 27th October 2023.
      </p>
      <h3>LIMITATION OF LIABILITY</h3>
      <p>
        You acknowledge and agree that your participation to the campaign and
        the services associated to it is done at your own discretion and risk
        and that you will be solely responsible for any resulting consequences.
        <br />
        Shukran SACCO expressly disclaims all warranties of any kind, whether
        express or implied, regarding the campaign and the services associated
        to it including but not limited to the implied warranties of
        merchantability, fitness for a particular purpose and non-infringement.
      </p>
      <h3>PRIVACY POLICY</h3>
      <p>
        Information collected about you or collected on your behalf is subject
        to our privacy policy (available on Shukran SACCO website). By
        participating in the campaign you consent to the collection and use of
        information as described in our privacy policy, as may be amended from
        time to time.
      </p>
      <h3>CONFIDENTIALITY</h3>
      <p>
        Each party under these Terms and Conditions shall keep confidential
        information regarded by the Disclosing party as confidential.
        <br />
        The Receiving party shall use the same degree of care it uses for its
        own confidential information to protect the Disclosing party’s
        confidential information from any use or disclosure.
      </p>
      <h3>ASSIGNMENT</h3>
      <p>
        The obligations under these Terms shall not be assigned by either party
        except with the written prior consent of the other party.
      </p>
      <h3>SEVERABILITY</h3>
      <p>
        If any term of these Terms and Conditions is held by a court of
        competent jurisdiction to be invalid or unenforceable, then the
        remaining terms will remain in force and effect as if such invalid or
        unenforceable term had never been included.
      </p>
      <h3>ENTIRE AGREEMENT</h3>
      <p>
        These Terms contain and constitute the entire understanding and
        agreement between the parties in connection to the subject matter herein
        and supersedes all prior agreements, written or oral, between the
        parties.
      </p>
      <h3>GOVERNING LAW</h3>
      <p>
        These Terms and Conditions shall be governed and construed in accordance
        to the Laws of Republic of Kenya.
      </p>
      <h3>CONTACT US</h3>
      <p>
        For questions or comments about these Terms and Conditions please
        contact through 0702039267 or 0758464586
      </p>
      <p>Published on 7th September 2023.</p>
    </div>
  );
}

export default Modalx2;
