import React from 'react';
import Faq from './subcomponents/Faq';
import './css/Faqs.css';

function Faqs() {
  return (
    <article className="faqs_article">
      <section className="faqs_article_section2">
        <figure className="faqs_article_section2_figure">
          <img
            className="faqs_article_section2_figure_img"
            src="images/resources/faqimage.png"
            alt="Faqs"
          />
        </figure>
        <section className="faqs_article_section2_mini">
          <h1 className="faqs_article_section2_mini_h1">
            Frequently Asked Questions
          </h1>
          <Faq />
        </section>
      </section>
    </article>
  );
}

export default Faqs;
