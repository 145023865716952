import React from 'react';
import { For } from 'million/react';
import './css/Home.css';
import HowItWork from './subcomponents/HowItWork';
import TestimonialHome from './subcomponents/TestimonialHome';
import HomeSecond from './subcomponents/HomeSecond';
import Partners2 from './subcomponents/Partners2';
import ShukranTipThree from './subcomponents/ShukranTipThree';
import ShukranTipFour from './subcomponents/ShukranTipFour';

const urls = [
  {
    id: 1,
    url: 'images/resources/lokal.png',
  },

  {
    id: 2,
    url: 'images/resources/kinetic.png',
  },

  {
    id: 3,
    url: 'images/resources/cym.png',
  },

  {
    id: 4,
    url: 'images/resources/launchpad.png',
  },

  {
    id: 5,
    url: 'images/resources/stn.png',
  },

  {
    id: 6,
    url: 'images/resources/ian.png',
  },
];

const tips = [
  {
    id: 1,
    tiph11: 'Make An Impact',
    tipdes: 'We’re driving financial inclusion among service workers, who don’t have access to formal financial services i.e.. 49% of the adult population in Kenya.',
    tipdes2: 'We also put more money into their pockets and allow them to secure their financial future by saving their tips.',
    tipimg: 'images/resources/makeanimp.png',
    tipbtn: 'Be a partner',
    tiplink: 'https://calendly.com/mathengewaweru/let-s-meet-shukran',
    des2: 'After 1 year of receiving and saving tips, service workers can have as much as 147,500shs, inclusive of 10%, on their savings.',
    des3: 'This is more than 97.4% of Kenyans, who have less than 100,000shs in their bank account.',
  },
];

const tips1 = [
  {
    id: 1,
    tipdes: 'The Match-a-Tip campaign is part of a large vision of achieving our primary goal, which is hitting $10 million in tip volume by end of 2024.',
    tipbtn: 'Be a partner',
    tiplink: 'https://calendly.com/mathengewaweru/let-s-meet-shukran',
  },
];

const howitwork = [
  {
    id: 1,
    hiw11: 'How It Works',
    keypoints: [
      {
        id: 1,
        pointimg: 'images/resources/icon1.svg',
        pointa: 'Service Worker Tipped',
        pointb: 'Service workers get tipped easily with the Shukran App',
      },
      {
        id: 2,
        pointimg: 'images/resources/icon2.svg',
        pointa: 'We Match The Tip',
        pointb: 'The service worker receives a match of the tip amount.',
      },
      {
        id: 3,
        pointimg: 'images/resources/icon3.svg',
        pointa: 'Enhance Financial Inclusion',
        pointb: 'We guide the service worker on how to secure their financial future.',
      },
    ],
  },
];

const TestimonialPartner = [
  {
    id: 1,
    testh2: 'Testimonials',
    testdes: '“I’m delighted to be working with Shukran as my savings have more than tripled since I joined the Sacco & started prioritizing my financial future.”',
    testname: 'Fenny',
    testrole: 'Service Worker - Ocean Basket',
  },

  {
    id: 2,
    testh2: 'Testimonials',
    testdes: 'Apart from receiving tips on the platform and directly saving it to my SACCO account, the SACCO also provides financial literacy classes that are a step forward into my financial freedom. I would like to encourage my fellow service workers to join Shukran Sacco today. This is a game changer.',
    testname: 'Clarence,',
    testrole: 'Sacco Member',
  },

  {
    id: 3,
    testh2: 'Testimonials',
    testdes: 'Tipping with Shukran is easy and convenient. Plus, when using your mobile money number with Shukran, they ensure your safety by keeping your number private.',
    testname: 'Ricky,',
    testrole: 'Owner of Bistro Adreno',
  },

  {
    id: 4,
    testh2: 'Testimonials',
    testname: 'Dave,',
    testrole: 'Tipper',
    testdes: 'The thing about Shukran is you have to ask for somebody’s number but I like the privacy, I don’t want my name and number circulating all over the place,',
  },
];

function MatchTip() {
  return (
    <main className="home_main_section">
      <HomeSecond />
      <div className="home_main_section_partners_hold section_partner2">
        <h2 className="main_testimonial_h2s h2_change">Our Partners</h2>
        <section className="home_main_section_partners">
          <Partners2 urls={urls} />
        </section>
      </div>
      <For each={tips}>
        {(tip) => (
          <ShukranTipThree
            key={tip.id}
            tiph11={tip.tiph11}
            tipdes={tip.tipdes}
            tipdes2={tip.tipdes2}
            tipimg={tip.tipimg}
            tipbtn={tip.tipbtn}
            tiplink={tip.tiplink}
            des2={tip.des2}
            des3={tip.des3}
          />
        )}
      </For>
      <For each={howitwork}>
        {(hiw) => (
          <HowItWork key={hiw.id} hiw11={hiw.hiw11} keypoints={hiw.keypoints} />
        )}
      </For>
      <For each={tips1}>
        {(tip) => (
          <ShukranTipFour
            key={tip.id}
            tipdes={tip.tipdes}
            tipbtn={tip.tipbtn}
            tiplink={tip.tiplink}
          />
        )}
      </For>
      <article className="testimonial_home">
        <TestimonialHome data={TestimonialPartner} />
      </article>
    </main>
  );
}

export default MatchTip;
