import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Faqs from './components/Faqs';
import Header from './components/subcomponents/Header';
import Testimonial from './components/Testimonial';
import Footer from './components/subcomponents/Footer';
import Home from './components/Home';
import Error from './components/subcomponents/Error';
import Comunity from './components/Comunity';
import Events from './components/Events';
import MatchTip from './components/MatchTip';
import Policy from './components/subcomponents/Policy';
import Terms from './components/subcomponents/Terms';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Comunity />} />
        <Route exact path="/home" element={<Home />} />
        {/* <Route path="/resource" element={<Resource />} /> */}
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/community" element={<Comunity />} />
        <Route path="/events" element={<Events />} />
        {/* <Route path="/wait-list" element={<WaitListForm />} /> */}
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="*" element={<Error />} />
        <Route path="/matchtip" element={<MatchTip />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
