import React from 'react';
import PropTypes from 'prop-types';
import './css/Partners.css';
import Banner from './Banner';

function Partners2({ urls }) {
  return (
    <Banner urls={urls} />
  );
}

Partners2.propTypes = {
  urls: PropTypes.string.isRequired,
};

export default Partners2;
