import React from 'react';
import PropTypes from 'prop-types';
import BasicRating from './Rating';
import './css/Service.css';
/* eslint-disable */
function ServiceWorker({
  des,
  name,
  position,
  img,
}) {
  return (
    <main className="service_main">
      <article className="service_main_article">
        <figure className="service_main_article_figure">
          <img src={img} alt="tipper images" className="service_main_article_figure_img"/>
        </figure>
        <section className="service_main_article_section">
          <p className="service_main_article_section_p">
            {des}
          </p>
          <div className="service_main_article_section_div1">
            <b className="service_main_article_section_div1_b">
              {name}
            </b>
            <span className="service_main_article_section_div1_span">
              {position}
            </span>
            <div className="service_main_article_section_div">
              <span className="service_main_article_section_div_span">
                App Rating:
              </span>
              <BasicRating />
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}

ServiceWorker.propTypes = {
  des: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default ServiceWorker;
