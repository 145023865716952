import React from 'react';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './css/HomeFirst.css';

function HomeSecond() {
  return (
    <main className="home_first_section">
      <article className="home_first_section_art">
        <h1 className="home_first_section_h1">
          Changing Lives
          <br />
          <span>is a Tip away.</span>
        </h1>
        <p className="home_first_section_p">
          We believe that an
          <span> equitable world </span>
          is a better world where more people have more
          {' '}
          <span>
            opportunities
          </span>
          {' '}
          in their hands.
        </p>
        <a className="home_first_section_btn_a" target="_blank" rel="noreferrer" href="https://calendly.com/mathengewaweru/let-s-meet-shukran">
          <button type="button" className="home_first_section_btn transition-all hover:bg-[#cfa247]">
            Be a partner
            <span><ArrowForwardIcon className="iconarrowforward" /></span>
          </button>
        </a>
        {/* <section className="home_first_section_section">
          <div className="home_first_section_section_div">
            <CheckCircleIcon className="home_first_section_section_div_icon1" />
            <p className="home_first_section_section_div_p">
              <b>Make an Impact</b>
              <br />
              Reward great service
              <br />
              <br />
            </p>
          </div>
          <div className="home_first_section_section_div">
            <CheckCircleIcon className="home_first_section_section_div_icon1" />
            <p className="home_first_section_section_div_p">
              <b>Privacy.</b>
              <br />
              Your phone number remains private
              <br />
              <br />
            </p>
          </div>
          <div className="home_first_section_section_div">
            <CheckCircleIcon className="home_first_section_section_div_icon1" />
            <p className="home_first_section_section_div_p">
              <b>Direct.</b>
              <br />
              Tip goes directly to your favorite service worker
            </p>
          </div>
        </section> */}
      </article>
      <figure className="home_first_section_figure">
        <img className="home_first_section_figure_img" src="images/resources/matchingtip.png" alt="hero" />
      </figure>
    </main>
  );
}

export default HomeSecond;
