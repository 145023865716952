import React from 'react';
import PropTypes from 'prop-types';
import './css/Partners.css';

function Partners({
  url,
}) {
  return (
    <figure className="partners_main_figure">
      <img className="partners_main_figure_img" src={url} alt="partners to shukran" />
    </figure>
  );
}

Partners.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Partners;
