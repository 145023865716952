import React from 'react';
import Modalx2 from './Modalx2';

function Policy() {
  return (
    <main>
      <Modalx2 />
    </main>
  );
}

export default Policy;
