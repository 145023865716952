import React from 'react';
import PropTypes from 'prop-types';
import './css/Restaurant.css';

function Restaurant({
  des,
  name,
  position,
  img,
}) {
  return (
    <main className="restaurant_main">
      <article className="restaurant_main_article">
        <figure className="restaurant_main_article_figure">
          <img src={img} alt="tipper images" className="restaurant_main_article_figure_img" />
        </figure>
        <section className="restaurant_main_article_section">
          <p className="restaurant_main_article_section_p">
            {des}
          </p>
          <div className="restaurant_main_article_section_div1">
            <b className="restaurant_main_article_section_div1_b">
              {name}
            </b>
            <span className="restaurant_main_article_section_div1_span">
              {position}
            </span>
          </div>
        </section>
      </article>
    </main>
  );
}

Restaurant.propTypes = {
  des: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default Restaurant;
