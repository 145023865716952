import React, { useState, useEffect } from 'react';
import data from '../data.json';
import './css/JoinCom.css';
import Carouselgrid from './Carouselgrid';

function JoinCom() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main className="bg-[#50A2A7] pt-[1rem] ">
      <div className="max-w-[1330px] mx-auto">
        {
          data.joincommunity.map((dat) => (
            <div key={dat.id} className="text-center w-[80%] mx-auto flex flex-col gap-[1rem] ">
              <h2 className="text-[1.5rem] uppercase font-['Nunito'] font-[500] my-[1rem] leading-[1.3] text-[#fff] md:text-[2rem]">
                {dat.head}
              </h2>
              {
                (windowWidth < 768) ? <Carouselgrid className="cursor-pointer" /> : (
                  <div id="joincommholderdiv">
                    {
                      dat.cards.map((da) => (
                        <div key={da.id} className="bg-[#fff] rounded-[8px] px-[2.078rem] py-[4.875rem]">
                          <figure className="flex justify-center ">
                            <img src={da.img} width={110} height={110} alt="icons" />
                          </figure>
                          <h3 className="text-[1.125rem] pt-[0.75rem] font-[600] font-['Nunito'] leading-[1.5] text-[#000]">
                            {da.h3tag}
                          </h3>
                          <p className="text-[#242321] pt-[0.75rem] text-[1.125rem] font-[500] font-['Nunito'] leading-[1.5]">
                            {da.des}
                          </p>
                        </div>
                      ))
                    }
                  </div>
                )
              }
              <a href={dat.link} target="_blank" rel="noreferrer" className="no-underline hover:no-underline text-center">
                <button type="button" className="bg-[#E9B44B] hover:bg-[#bd871b] hover:text-[#fff] transition-all rounded-[5px] mt-[0rem] mb-[1.5rem] text-[1.3rem] py-[0.8rem] px-[1.5rem] w-[80%] mx-auto text-[#fff] font-['Nunito] leading-[1.4] font-[800] md:w-[20%] md:my-[1.5rem]">
                  Join now
                </button>
              </a>
            </div>
          ))
        }
      </div>
    </main>
  );
}

export default JoinCom;
