import React from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import './css/Error.css';

function Error() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Link
              to="/"
              className="errorbtn"
              variant="contained"
              style={{
                padding: '15px 20px 15px 22px',
                backgroundColor: '#f2c773',
                color: '#fff',
                textDecoration: 'none',
                marginTop: '2rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Back Home
            </Link>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              width={500}
              height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Error;
