import React from 'react';
import './css/Modalx.css';

function Modalx() {
  return (
    <div className="box">
      <div className="box-div">
        <h3>
          SHUKRAN FINANCE PLATFORM LIMITED PRIVACY POLICY.
        </h3>
        <h3>
          POLICY STATEMENT.
        </h3>
        <p>
          Shukran Finance Platform Limited (referred to as "Shukran" or "we") is
          committed to ensuring the privacy and accuracy of your information. By
          "your information," we mean any information about you that you provide
          to us.
          <br />
          <br />
          This privacy policy applies to the collection and use of personal
          information received through the following means:
          <ul className="unorde_lis">
            <li>Information collected through our website.</li>
            <li>
              Information collected through phone calls, contracts, agreements,
              emails, or any other means of collecting personal information.
            </li>
          </ul>
        </p>
        <h3 id="modal-modal-description">
          DATA PROTECTION PRINCIPLES
        </h3>
        <p>
          In compliance with the Data Protection Act, 2019, we ensure that the
          personal information we hold about you:
          <ul className="unorde_lis">
            <li>Is used lawfully, fairly, and transparently.</li>
            <li>
              Is used only for the purposes for which it was collected and not
              in any way incompatible with those purposes.
            </li>
            <li>Is accurate and kept up to date.</li>
            <li>
              Is kept only as long as necessary for the purposes for which it
              was collected.
            </li>
            <li>Is kept securely.</li>
          </ul>
        </p>
        <h3 id="modal-modal-description">
          COLLECTION OF YOUR PERSONAL INFORMATION
        </h3>
        <p>
          Information we receive from our clients remains their sole and
          exclusive property and is used only for the agreed-upon purposes. This
          information is considered confidential, and our employees uphold the
          highest standards of confidentiality.
          <br />
          <br />
          The information we collect depends on the category of persons we are
          dealing with, which includes:
        </p>
        <h3 id="modal-modal-description">
          1. TIPPER
        </h3>
        <p>
          We collect the following information from the tipper:
          <ul>
            <li>Full name.</li>
            <li>Phone number used for transactions via M-Pesa.</li>
            <li>Preferred password.</li>
          </ul>
        </p>
        <h3>THE COLLECTED INFORMATION IS USED FOR:</h3>
        <ul>
          <li>Creating an account for the tipper.</li>
          <li>Authentication purposes.</li>
          <li>Communication purposes.</li>
        </ul>
        <h3 id="modal-modal-description">
          2. SERVICE WORKER
        </h3>
        <p>
          We collect the following information from the service worker:
          <ul>
            <li>Full name.</li>
            <li>Phone number used for transactions via M-Pesa.</li>
            <li>Preferred password.</li>
          </ul>
        </p>
        <h3 id="modal-modal-description">
          THE COLLECTED INFORMATION IS USED FOR:
        </h3>
        <p>
          - Creating an account for the service worker. - Authentication
          purposes. - Communication purposes.
        </p>
        <h3 id="modal-modal-description">
          COOKIES
        </h3>
        <p>
          We use unique identifiers called "Cookies" to collect anonymous,
          non-personally identifiable information. These cookies are used to
          provide you with a more customized user experience and improve the
          design and functionality of our website.
        </p>
        <h3 id="modal-modal-description">
          THIRD PARTY
        </h3>
        <p>
          Shukran follows strict privacy practices and procedures in regard to
          the disclosure of personal information. We may engage trusted
          third-party companies and individuals to perform services on our
          behalf, such as payment processing and website hosting. These service
          providers have access to your personal information only to the extent
          necessary to perform their functions and are obligated to maintain its
          confidentiality and security. However, we cannot guarantee the actions
          of these third parties and shall not be held liable for any
          unauthorized use or disclosure of your personal information by them.
        </p>
        <h3 id="modal-modal-description">
          SECURITY OF YOUR PERSONAL DATA
        </h3>
        <p>
          We have implemented appropriate security measures to protect your
          personal information against unauthorized access or processing. We
          have also established policies and procedures to safeguard your
          personal information. To access our site, please ensure a secure
          environment.
        </p>
        <h3 id="modal-modal-description">
          DATA SUBJECT'S RIGHTS
        </h3>
        <p>
          As a data subject, you have the following rights under the Data
          Protection Act, 2019:
          <ul>
            <li>
              The right to be informed of how your personal data will be used.
            </li>
            <li>The right to access personal information.</li>
            <li>
              The right to object to the processing of all or part of your
              personal data
            </li>
            <li>The right to correct false or misleading data.</li>
            <li>The right to delete false or misleading data.</li>
          </ul>
        </p>
        <h3 id="modal-modal-description">
          LINKS TO OTHER WEBSITES
        </h3>
        <p>
          Our site may contain links to third-party websites not operated by us.
          We encourage you to review the privacy policies of these websites. We
          have no control over and assume no responsibility for the content,
          privacy policies, or procedures of any third-party sites or services.
        </p>
        <h3 id="modal-modal-description">
          CHANGES TO THIS PRIVACY POLICY
        </h3>
        <p>
          We reserve the right to update and make changes to this policy at any
          time. If changes occur, we will notify you by posting the updated
          privacy policy on our website. It is advisable to periodically review
          this privacy policy for any changes.
        </p>
        <h3 id="modal-modal-description">
          CONTACT
        </h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at hello@shukran.co.
        </p>
      </div>
    </div>
  );
}

export default Modalx;
