import React from 'react';
import './css/HeroCom.css';
import data from '../data.json';

function HeroCom() {
  return (
    <article className="xl:h-[90vh]" id="hero_header">
      {data.herocommunity.map((dat) => (
        <div id="wrapper_video" className="max-w-[320px]:pt-[4rem] max-w-[320px]:gap-[0rem] relative w-[100%] mx-auto text-center flex flex-col gap-[2rem] pt-[7rem] md:gap-[2rem] md:w-[100%] lg:gap:[2rem] xl:gap-[1rem] xl:pt-[11rem]" key={dat.id}>
          <video
            poster="/images/resources/herocom.png"
            autoPlay
            loop
            muted
            className="absolute top-0 left-0 object-cover w-[100vw] h-[100vh] z-[-1]"
          >
            <source src="/images/resources/videoheros.mp4" type="video/mp4" />
          </video>
          <h1 className="max-w-[320px]:text-[1.5rem] text-[#fff] w-[100%] uppercase text-[2rem] leading-[1.3] font-['Nunito'] font-[800] md:text-[3rem] md:w-[100%] mx-auto xl:w-[30%] xl:text-[3rem]">
            {dat.head}
          </h1>
          <p className="font-['Nunito'] uppercase p-[3rem] text-[1.125rem] text-[#fff] md:text-[1.3rem] md:p-[1.2rem] xl:text-[1.4rem] max-[370px]:p-[0rem]">
            {dat.des}
          </p>
          <a href={dat.link} target="_blank" rel="noreferrer" className="no-underline hover:no-underline text-center mt-[2rem] w-[50%] mx-auto xl:w-[35%]">
            <button type="button" className="bg-[#E9B44B] grayscale-1 hover:bg-[#bd871b] hover:text-[#fff] transition-all rounded-[5px] text-[1.125rem] py-[0.8rem] px-[1rem] w-[80%] mx-auto text-[#242321] font-['Nunito] leading-[1.1] font=[800] md:w-[35%] md:text-[1.3rem]">
              {dat.btn}
            </button>
          </a>
          <a href="https://www.youtube.com/embed/sKsVtaYZX6A?si=jssiHCImIqrjZmIg" target="_blank" rel="noreferrer" className="home_first_div_div_holder">
            <img src="/images/resources/play2.png" loading="lazy" width={30} height={30} alt="play" className="grayscale-[1] mt-[2rem]" />
          </a>
        </div>
      ))}
    </article>
  );
}

export default HeroCom;
