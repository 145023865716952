import React from 'react';
import PropTypes from 'prop-types';
import './css/ShukranTipTwo.css';

function ShukranTipTwo({
  tiph11, tipdes, tipimg, tipbtn, tiplink, keypoints,
}) {
  return (
    <main className="shukran_tp_two_section">
      <h2 className="shukran_tp_two_section_h2">{tiph11}</h2>
      <p className="shukran_tp_two_section_p">{tipdes}</p>
      <article className="shukran_tp_two_section_article">
        <div className="shukran_tp_two_section_article_div">
          {keypoints.map((point) => (
            <div
              key={point.id}
              className="shukran_tp_two_section_article_div_divs"
            >
              <img
                className="shukran_tp_two_section_article_div_divs_img"
                src="images/resources/check-square.svg"
                width={20}
                height={30}
                alt="check"
              />
              <div className="shukran_tp_two_section_article_div_divs_div">
                <b className="shukran_tp_two_section_article_div_divs_div_b">
                  {point.pointa}
                </b>
                <p className="shukran_tp_two_section_article_div_divs_div_p">
                  {point.pointb}
                </p>
              </div>
            </div>
          ))}
        </div>
        <figure className="shukran_tp_two_section_article_fig">
          <img
            className="shukran_tp_two_section_article_fig_img"
            src={tipimg}
            alt="tip"
          />
        </figure>
      </article>
      <div className="div_shukran_tip_holder_two">
        <a
          className="shukran_tp_two_section_btn_a"
          href={tiplink}
          target="_blank"
          rel="noreferrer"
        >
          <button type="button" className="shukran_tp_two_section_btn hover:bg-[#38979c] transition-all">
            {tipbtn}
          </button>
        </a>
      </div>
    </main>
  );
}

ShukranTipTwo.propTypes = {
  tiph11: PropTypes.string.isRequired,
  tipdes: PropTypes.string.isRequired,
  tipimg: PropTypes.string.isRequired,
  tipbtn: PropTypes.string.isRequired,
  tiplink: PropTypes.string.isRequired,
  keypoints: PropTypes.array.isRequired, //eslint-disable-line
};

export default ShukranTipTwo;
