import PropTypes from 'prop-types';
import './css/Banner.css';

function Banner({ urls, speed = 15000 }) {
  return (
    <div className="inner">
      <div className="wrapper">
        <section style={{ '--speed': `${speed}ms` }}>
          {urls.map(({ id, url }) => (
            <div className="image" key={id}>
              <img src={url} alt={id} />
            </div>
          ))}
        </section>
        <section style={{ '--speed': `${speed}ms` }}>
          {urls.map(({ id, url }) => (
            <div className="image" key={id}>
              <img src={url} alt={id} />
            </div>
          ))}
        </section>
        <section style={{ '--speed': `${speed}ms` }}>
          {urls.map(({ id, url }) => (
            <div className="image" key={id}>
              <img src={url} alt={id} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

Banner.propTypes = {
  urls: PropTypes.string.isRequired,
  speed: PropTypes.number.isRequired,
};

export default Banner;
