import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './css/Header.css';

/* eslint-disable */
function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const communityhead =
    location.pathname.startsWith("/community") ||
    location.pathname.startsWith("/events");
  return (
    <header className="header">
      <article className="header_article">
        <HashLink smooth to="https://shukran.co/" className="header_article_figure">
          <img
            src="images/resources/shukranbluelogo.png"
            className="header_article_figure_img"
            alt="blue shukran logo"
          />
        </HashLink>
        <nav className={`header_article_nav ${isMenuOpen ? "open" : ""}`}>
          {communityhead ? (
            <HashLink
              to="/"
              onClick={() => setIsMenuOpen(false)}
              className="header_article_a"
            >
              Home
            </HashLink>
          ) : (
            <a
              href="https://www.shukransacco.com/?utm_source=tippingLP&utm_medium=web"
              onClick={() => setIsMenuOpen(false)}
              className="header_article_a"
              rel="noreferrer"
              target="_blank"
            >
              Shukran Sacco
            </a>
          )}
          <HashLink
            smooth
            to="/matchtip"
            onClick={() => setIsMenuOpen(false)}
            className="header_article_a"
          >
            Tip Matching
          </HashLink>
          {communityhead ? (
            <HashLink
              to="/community/#events"
              onClick={() => setIsMenuOpen(false)}
              className="header_article_a"
            >
              Events
            </HashLink>
          ) : (
            <HashLink
              smooth
              to="/"
              onClick={() => setIsMenuOpen(false)}
              className="header_article_nav_link_a hover:underline"
            >
              Shukran Community
            </HashLink>
          )}

          <div className="dropdown">
            {communityhead ? (
              " "
            ) : (
              <button type="button" className="header_article_nav_link dropbtn">
                Resources
                <span className="dropbtn_span">
                  <KeyboardArrowDownIcon />
                </span>
              </button>
            )}
            <div className="dropdown-content">
              <HashLink
                smooth
                to="/testimonial"
                onClick={() => setIsMenuOpen(false)}
                className="header_article_nav_link_a"
              >
                Testimonials
              </HashLink>
              <HashLink
                smooth
                to="/faqs"
                className="header_article_nav_link_a"
                onClick={() => setIsMenuOpen(false)}
              >
                FAQs
              </HashLink>
            </div>
          </div>
          <button
            type="button"
            className="header_article_nav_link buttones hover:bg-[#50A2A7] hover:text-[#fff] transition-all"
          >
            {communityhead ? (
              <HashLink
                to="/"
                style={{ textDecoration: "none" }}
                className="hover:text-[#fff]"
              >
                Join community
              </HashLink>
            ) : (
              <HashLink
                to="/#appstore"
                style={{ textDecoration: "none" }}
                className="hover:text-[#fff]"
              >
                Get The App
              </HashLink>
            )}
          </button>
        </nav>
        <button
          type="button"
          className="icon"
          id="button"
          onClick={handleMenuToggle}
        >
          <i className="fa fa-bars" />
        </button>
      </article>
    </header>
  );
}

export default Header;
