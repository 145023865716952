import React from 'react';
import HomeFirst from './subcomponents/HomeFirst';
import Partners from './subcomponents/Partners';
import './css/Home.css';
import ShukranTip from './subcomponents/ShukranTip';
import ShukranTipTwo from './subcomponents/ShukranTipTwo';
import HowItWork from './subcomponents/HowItWork';
import TestimonialHome from './subcomponents/TestimonialHome';

const urls = [
  {
    id: 1,
    url: 'images/resources/ashaku.png',
  },

  {
    id: 2,
    url: 'images/resources/koroga.png',
  },

  {
    id: 3,
    url: 'images/resources/karen.png',
  },

  {
    id: 4,
    url: 'images/resources/bistro.png',
  },
];

const tips = [
  {
    id: 1,
    tiph11: 'Why Tip with Shukran?',
    tipdes:
      'We exist to ensure the most deserving service workers are rewarded and increase the tipping culture through our digital tipping platform.',
    tipimg: 'images/resources/shukranTip.webp',
    tipbtn: 'Start Tipping',
    tiplink:
      'https://app.shukran.co/?utm_source=homepage&utm_medium=web&utm_term=cta2',
    keypoints: [
      {
        id: 1,
        pointa: 'Make an Impact',
        pointb: 'Reward great service',
      },
      {
        id: 2,
        pointa: 'Privacy',
        pointb: 'Your phone number stays /remains private',
      },
      {
        id: 3,
        pointa: 'Direct',
        pointb: 'Your tip goes directly to deserving service workers',
      },
      {
        id: 4,
        pointa: 'Go Cashless',
        pointb: 'No need to carry cash to leave a tip',
      },
    ],
  },
];

const tips1 = [
  {
    id: 1,
    tiph11: 'Shukran For Service Workers',
    tipdes:
      'Shukran is here to make sure that deserving workers like yourself are rewarded! Here are some of the benefits of joining Shukran:',
    tipimg: 'images/resources/smilecust.webp',
    tipbtn: 'Join Sacco',
    tiplink: 'https://www.shukransacco.com?utm_source=tippingLP&utm_medium=web',
    keypoints: [
      {
        id: 1,
        pointa: 'More Tips',
        pointb: 'Increase your income through tips with Shukran',
      },
      {
        id: 2,
        pointa: 'Card Tipping',
        pointb: 'Receive tips directly from card',
      },
      {
        id: 3,
        pointa: 'Privacy',
        pointb: 'Your phone number remains private when receiving a tip',
      },
      {
        id: 4,
        pointa: 'Invest',
        pointb: 'Secure future yako na tips',
      },
    ],
  },
];

const tipstwo = [
  {
    id: 1,
    tiph11: 'Why Shukran At Your Restaurant?',
    tipbtn: 'Join Community',
    tipdes:
      'Shukran is here to increase the tipping culture, amplify the Kenyan dining  scene to encourage better restaurant experiences. As a restaurant owner, here is what you get:',
    tipimg: 'images/resources/shukrana.webp',
    tiplink: 'https://kj47911bdgs.typeform.com/to/S0rzKtyz',
    keypoints: [
      {
        id: 1,
        pointa: 'Better Service Experience',
        pointb:
          'More tips, motivated staff, better service, satisfied customers.',
      },
      {
        id: 2,
        pointa: 'Free Marketing',
        pointb:
          'Hype up your business through free marketing features such as videos, testimonials and podcasts.',
      },
      {
        id: 3,
        pointa: 'Community',
        pointb:
          'Join a community for restaurant owners to network, learn and share industry trends.',
      },
    ],
  },
];

const howitwork = [
  {
    id: 1,
    hiw11: 'How It Works',
    keypoints: [
      {
        id: 1,
        pointimg: 'images/resources/icon1.svg',
        pointa: 'Search',
        pointb: 'Ask your service worker for their Shukran ID or phone number',
      },
      {
        id: 2,
        pointimg: 'images/resources/icon2.svg',
        pointa: 'Select',
        pointb: 'Tip directly from M-pesa or Card',
      },
      {
        id: 3,
        pointimg: 'images/resources/icon3.svg',
        pointa: 'Tip',
        pointb: 'Select tip amount and send to your service worker',
      },
    ],
  },
];

const howitwork1 = [
  {
    id: 1,
    hiw11: 'How It Works',
    keypoints: [
      {
        id: 1,
        pointimg: 'images/resources/icon4.svg',
        pointa: 'Register',
        pointb: 'Create a service worker account  on Shukran',
      },
      {
        id: 2,
        pointimg: 'images/resources/icon5.svg',
        pointa: 'Join ',
        pointb: 'Join Shukran SACCO to start saving tips',
      },
      {
        id: 3,
        pointimg: 'images/resources/icon6.svg',
        pointa: 'Receive Tips',
        pointb: 'Receive more tips and secure your financial future',
      },
    ],
  },
];

const TestimonialPartner = [
  {
    id: 1,
    testh2: 'Testimonials',
    testdes:
      '“Shukran has a very good concept that should be adopted by more if not all restaurants of Kenya because tipping  is a good culture that needs to be embraced by everyone who gets served by anyone when they go to other restaurants.”',
    testname: 'Jackie',
    testrole: 'Owner at Lapadella',
  },

  {
    id: 2,
    testh2: 'Testimonials',
    testdes:
      '“Tipping with Shukran is easy and convenient. Plus, when using your mobile money number with Shukran, they ensure your safety by keeping your number private.”',
    testname: 'Ricky',
    testrole: 'Owner of Bistro Adreno',
  },
];

const Home = () => {  //eslint-disable-line
  return (
    <main className="home_main_section">
      <HomeFirst />
      <div className="home_main_section_partners_hold">
        <h2 className="main_testimonial_h2s h2_change">Our Partners</h2>
        <section className="home_main_section_partners">
          {
            urls.map((url) => (
              <Partners key={url.id} url={url.url} />
            ))
          }
        </section>
      </div>
      {
        tips.map((tip) => (
          <ShukranTip
            key={tip.id}
            tiph11={tip.tiph11}
            tipdes={tip.tipdes}
            tipimg={tip.tipimg}
            tipbtn={tip.tipbtn}
            tiplink={tip.tiplink}
            keypoints={tip.keypoints}
          />
        ))
      }
      {
        howitwork.map((hiw) => (
          <HowItWork key={hiw.id} hiw11={hiw.hiw11} keypoints={hiw.keypoints} />
        ))
      }
      {
        tips1.map((tip) => (
          <ShukranTip
            key={tip.id}
            tiph11={tip.tiph11}
            tipdes={tip.tipdes}
            tipimg={tip.tipimg}
            tipbtn={tip.tipbtn}
            tiplink={tip.tiplink}
            keypoints={tip.keypoints}
          />
        ))
      }
      {
        howitwork1.map((hiw) => (
          <HowItWork key={hiw.id} hiw11={hiw.hiw11} keypoints={hiw.keypoints} />
        ))
      }
      {
        tipstwo.map((tip) => (
          <ShukranTipTwo
            key={tip.id}
            tiph11={tip.tiph11}
            tipdes={tip.tipdes}
            tipimg={tip.tipimg}
            tipbtn={tip.tipbtn}
            tiplink={tip.tiplink}
            keypoints={tip.keypoints}
          />
        ))
      }
      <article className="testimonial_home">
        <TestimonialHome data={TestimonialPartner} />
      </article>
    </main>
  );
};

export default Home;
