import React from 'react';
import PropTypes from 'prop-types';
import './css/ShukranTip.css';

function ShukranTipFour({
  tipdes,
  tipbtn,
  tiplink,
}) {
  return (
    <main className="shukran_tp_section">
      <p className="shukran_tp_section_p des_in_sect_four">
        {
          tipdes.split(' ').map((word, index) => (
            <span key={index} className={word === '$10' || word === 'million' ? 'highlightfive' : ''}>
              {word}
              {' '}
            </span>
          ))
        }
      </p>
      <div className="div_shukran_tip_holder">
        <a className="shukran_tp_section_btn_a shukran_tp_section_btn" href={tiplink} target="_blank" rel="noreferrer">
          <button type="button" className="shukran_tp_section_btn color_btn_yellow transition-all hover:bg-[#dbab49]">
            {tipbtn}
          </button>
        </a>
      </div>
    </main>
  );
}

ShukranTipFour.propTypes = {
  tipdes: PropTypes.string.isRequired,
  tipbtn: PropTypes.string.isRequired,
  tiplink: PropTypes.string.isRequired,
};

export default ShukranTipFour;
