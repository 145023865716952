import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './css/TestimonialHome.css';

function TestimonialHome({ data }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {
          data.map((dat) => (
            <SwiperSlide key={dat.id} className="swiper_testimonial">
              <h2 className="swiper_testimonial_h2">
                {dat.testh2}
              </h2>
              <p className="swiper_testimonial_p">
                {dat.testdes}
              </p>
              <div className="swiper_testimonial_div">
                <b className="swiper_testimonial_div_b">
                  {dat.testname}
                </b>
                <span className="swiper_testimonial_div_span">
                  {dat.testrole}
                </span>
              </div>
            </SwiperSlide>
          ))
        }
        <div className="swiper-button image-swiper-button-next">
          <ArrowForwardIosIcon />
        </div>
        <div className="swiper-button image-swiper-button-prev">
          <ArrowBackIosIcon />
        </div>
      </Swiper>
    </>
  );
}

TestimonialHome.propTypes = {
  data: PropTypes.array.isRequired, //eslint-disable-line
};

export default TestimonialHome;
