import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './css/EventCom.css';
import Event from './Event';

function EventCom() {
  return (
    <main className="bg-[#fff] text-[#000]">
      <Event />
      <div className="flex justify-center mb-[3.13rem]">
        <HashLink
          className="bg-[#E9B44B] hover:bg-[#bd871b] hover:text-[#fff] transition-all text-center  rounded-[5px] mt-[1rem] mb-[1.3rem] text-[1.125rem] py-[0.8rem] px-[1.5rem] w-[250px] mx-auto text-[#242321] font-['Nunito] leading-[1.1] font-[800]"
          smooth
          to="/events#events"
        >
          MORE EVENTS
        </HashLink>
      </div>
    </main>
  );
}

export default EventCom;
