import React from 'react';
import Event from './subcomponents/Event';

function Events() {
  return (
    <div className="max-w-[1330px] mx-auto">
      <Event />
    </div>
  );
}

export default Events;
