import React from 'react';
import data from '../data.json';
import './css/AboutCom.css';

function AboutCom() {
  return (
    <article className="bg-[#50a2a780]">
      <div className="max-w-[1330px] mx-auto">
        {data.aboutcommunity.map((dat) => (
          <div
            key={dat.id}
            id="community_gallery"
            className="flex flex-col text-center gap-4 py-6 w-[80%] mx-auto"
          >
            <h2 className="text-[#000] uppercase font-['Nunito'] text-[1.5rem] font-[600] pb-4 text-center leading-[1.3]">
              {dat.head}
            </h2>
            <div className="flex flex-col gap-8 md:flex-row-reverse">
              <figure className="md:flex-[0.5]">
                <img src={dat.img} className="object-contain" alt="about img" />
              </figure>
              <p className="text-[#000] font-['Nunito'] text-[1.125rem] leading-[1.5] font-[500] md:text-left md:flex-[0.5]">
                {dat.des}
              </p>
            </div>
            <div>
              <a href="https://kj47911bdgs.typeform.com/to/S0rzKtyz" rel="noreferrer" target="_blank">
                <button
                  className="bg-[#E9B44B] hover:bg-[#bd871b] hover:text-[#fff] transition-all rounded-[5px] mt-[1rem] mb-[1.3rem] text-[1.125rem] py-[0.8rem] px-[1.5rem] w-[250px] mx-auto text-[#242321] font-['Nunito] leading-[1.1] font-[800]"
                  type="button"
                >
                  {dat.btn}
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

export default AboutCom;
