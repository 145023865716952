import React, { useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './css/Faq.css';

function Faq() {
  const [reveal, setReveal] = useState(false);
  const [reveal1, setReveal1] = useState(false);
  const [reveal2, setReveal2] = useState(false);
  const [reveal3, setReveal3] = useState(false);

  const handleReveal = () => {
    setReveal(!reveal);
  };

  const handleReveal1 = () => {
    setReveal1(!reveal1);
  };

  const handleReveal2 = () => {
    setReveal2(!reveal2);
  };

  const handleReveal3 = () => {
    setReveal3(!reveal3);
  };
  return (
    <section className="faq_section">
      <button type="button" className="faq_section_button" onClick={handleReveal}>
        Why Should I tip with Shukran?
        {
        reveal ? (
          <span>
            <CloseOutlinedIcon />
          </span>
        ) : (
          <span>
            <AddOutlinedIcon />
          </span>
        )
        }
      </button>
      {
        reveal ? (
          <div className="faq_section_div">
            <p>
              Simple, Tipping with Shukran allows you the tipper
              to tip privately and directly to the Service Worker.
              {' '}
              Additionally,
              {' '}
              the tips you give have a major impact to the Service worker you are tipping.
              {' '}
              We allow them to save those tips,
              {' '}
              invest them,
              {' '}
              and use the tips for loans.
            </p>
          </div>
        ) : ''
      }
      <button type="button" className="faq_section_button" onClick={handleReveal1}>
        Why do we exist?
        {
        reveal1 ? (
          <span>
            <CloseOutlinedIcon />
          </span>
        ) : (
          <span>
            <AddOutlinedIcon />
          </span>
        )
        }
      </button>
      {
        reveal1 ? (
          <div className="faq_section_div">
            <p>
              We exist to ensure the most deserving service workers get rewarded.
              {' '}
              We also believe that a more equitable world is a better world. Most of all,
              {' '}
              we know that tipping really does change lives
              {' '}
              so our platform allows your tips to truly
              {' '}
              impact the lives of the people you are tipping.
            </p>
          </div>
        ) : ''
      }

      <button type="button" className="faq_section_button" onClick={handleReveal2}>
        How does it work?
        {
        reveal2 ? (
          <span>
            <CloseOutlinedIcon />
          </span>
        ) : (
          <span>
            <AddOutlinedIcon />
          </span>
        )
        }
      </button>
      {
        reveal2 ? (
          <div className="faq_section_div">
            <p>
              It’s really simple! When you scan the
              {' '}
              QR code or click the link you’ll be taken to our responsive website,
              {' '}
              where you can pay your server a tip with Mpesa or Card!
              {' '}
              The amount you pay is sent straight to their account.
            </p>
          </div>
        ) : ''
      }

      <button type="button" className="faq_section_button" onClick={handleReveal3}>
        Can I tip at a restaurant which doesn’t have Shukran at it?
        {
        reveal3 ? (
          <span>
            <CloseOutlinedIcon />
          </span>
        ) : (
          <span>
            <AddOutlinedIcon />
          </span>
        )
        }
      </button>
      {
        reveal3 ? (
          <div className="faq_section_div">
            <p>
              Yes you can! The beauty about Shukran is that you can tip whatever
              {' '}
              service worker you come across which has offered you a great service.
              {' '}
              All you need is to enter their phone number when you get on the app,
              {' '}
              choose the amount and how you would like to pay and then all done!
            </p>
          </div>
        ) : ''
      }
    </section>
  );
}

export default Faq;
