/**
 * The `Event` component is a React component that displays a list of events based on the current
 * location and window width.
 * @returns The `Event` component is returning a JSX element.
 */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import data from '../data.json';
import './css/EventCom.css';

function Event() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const InEvents = location.pathname.startsWith('/events');
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="max-w-[1330px] mx-auto">
      {data.eventcommunity.map((event) => (
        <div key={event.id}>
          <h2 id="events" className="text-[1.5rem] uppercase text-center font-['Nunito'] font-[600] my-[1rem] leading-[1.3] text-[#000] pt-[2.5rem] md:text-[2rem]">
            {event.head}
          </h2>
          <div className="mb-[3rem] w-[80%] mx-auto" id="eventcom_holder_card">
            {event.card.map((car, index) => (
              ((InEvents ? windowWidth < 768 : windowWidth < 768 && index < 3)) || (InEvents ? windowWidth >= 768 : (windowWidth >= 768 && index < 6)) ? (
                <div
                // max-w-[390px] max-h-[310px]
                  className="rounded-[16.635px] aspect-[390/310] min-[708px]:mx-auto min-[819px]:mx-0 mt-[2rem] justify-end flex flex-col bg-[#fff]"
                  id="card_event_holder"
                  key={car.id}
                >
                  <img
                    className="object-cover mx-auto bg-top w-[397px] h-[313px]"
                    src={car.img}
                    alt="cardimage"
                  />
                  <div className="flex gap-[2rem] mt-[1rem]">
                    <div className="flex flex-col pl-4">
                      <span className="text-[1.316rem] font-['Nunito'] font-[500] leading-[1.4] uppercase">
                        {car.month}
                      </span>
                      <span className="font-[500] text-[#E9B44B] text-center leading-[1.1] font-['Nunito'] text-[1.125rem]">
                        {car.date}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2 overflow-hidden">
                      <h3 className="md:text-[1.3rem] font-[500] m-0 leading-[1.4] font-['Nunito'] text-left text-[#000] truncate ">
                        {car.event}
                      </h3>
                      <p className="flex gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="22"
                          viewBox="0 0 17 22"
                          fill="none"
                        >
                          <path
                            d="M7.47631 21.007C1.57552 12.4527 0.480225 11.5747 0.480225 8.43085C0.480225 4.12444 3.97124 0.633423 8.27765 0.633423C12.5841 0.633423 16.0751 4.12444 16.0751 8.43085C16.0751 11.5747 14.9798 12.4527 9.079 21.007C8.69177 21.5664 7.8635 21.5664 7.47631 21.007ZM8.27765 11.6798C10.072 11.6798 11.5266 10.2252 11.5266 8.43085C11.5266 6.63651 10.072 5.18192 8.27765 5.18192C6.48331 5.18192 5.02872 6.63651 5.02872 8.43085C5.02872 10.2252 6.48331 11.6798 8.27765 11.6798Z"
                            fill="#023844"
                          />
                        </svg>
                        <span className="font-['Nunito'] text-[#000] font-[500] leading-[1.5] text-[1.125rem] truncate">
                          {car.location}
                        </span>
                      </p>
                    </div>
                  </div>
                  <a href={car.link} target="_blank" rel="noreferrer" className="no-underline hover:no-underline text-center">
                    <button
                      className="bg-[#E9B44B] hover:bg-[#bd871b] hover:text-[#fff] transition-all rounded-[5px] mt-[1rem] mb-[1.3rem] text-[1.125rem] py-[0.8rem] px-[1.5rem] w-[70%] mx-auto text-[#242321] font-['Nunito] leading-[1.1] font-[800]"
                      type="button"
                    >
                      {car.cta}
                    </button>
                  </a>
                </div>
              ) : null
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Event;
