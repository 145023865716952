import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from '../data.json';
import './css/GalleryCom.css';

/* eslint-disable */
function GalleryCom() {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "150px",
    slidesToShow: 1.7,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.35,
        },
      },
    ],
  };
  return (
    <main className="mx-auto bg-[#F5FFFE]">
      <div className="w-full pt-6 pb-4  mx-auto">
        <h2 className="text-[#000] font-['Nunito'] uppercase text-[1.5rem] font-[600] pb-4 text-center leading-[1.3] md:text-[2rem]">
          Gallery
        </h2>
        <div className="xl:w-[80%] mx-auto w-[800px]">
          {data.gallerycommunity.map((dat) => (
            <Slider {...settings} key={dat.id}>
              {dat.images.map((imge) => (
                <div
                  className="flex items-center justify-center px-2"
                  key={imge.id}
                >
                  <figure className="figure_holder">
                    <img
                      src={imge.img}
                      className="rounded-[5.938px]"
                      alt="imag"
                    />
                  </figure>
                </div>
              ))}
            </Slider>
          ))}
        </div>
      </div>
    </main>
  );
}

export default GalleryCom;
