import React from 'react';
import PropTypes from 'prop-types';
import './css/ShukranTip.css';

function ShukranTipThree({
  tiph11,
  tipdes,
  tipdes2,
  tipimg,
  tipbtn,
  tiplink,
  des2,
  des3,
}) {
  return (
    <main className="shukran_tp_section">
      <h2 className="shukran_tp_section_h2">
        {tiph11}
      </h2>
      <p className="shukran_tp_section_p shukran_tp_section_p_tipthree first_tip_three">
        {
          tipdes.split(' ').map((word, index) => (
            <span key={index} className={word === '49%' || word === 'of' || word === 'the' || word === 'adult' || word === 'population' || word === 'in' || word === 'Kenya.' ? 'highlightthree' : ''}>
              {word}
              {' '}
            </span>
          ))
        }
      </p>
      <p className="shukran_tp_section_p shukran_tp_section_p_tipthree">
        {
          tipdes2.split(' ').map((word, index) => (
            <span key={index} className={word === 'saving' || word === 'tips.' ? 'highlightthree' : ''}>
              {word}
              {' '}
            </span>
          ))
        }
      </p>
      <article className="shukran_tp_section_article">
        <figure className="shukran_tp_section_article_fig">
          <img className="shukran_tp_section_article_fig_img" src={tipimg} alt="tip" />
        </figure>
        <div className="shukran_tp_section_article_div">
          <p className="shukran_tp_section_p four_p_tag">
            {
              des2.split(' ').map((word, index) => (
                <span key={index} className={word === '1' || word === 'year' || word === '147,500shs,' || word === '10%,' ? 'highlightfour' : ''}>
                  {word}
                  {' '}
                </span>
              ))
            }
          </p>
          <p className="shukran_tp_section_p">
            {
                des3.split(' ').map((word, index) => (
                  <span key={index} className={word === '97.4%' || word === '100,000shs' ? 'highlightfour' : ''}>
                    {word}
                    {' '}
                  </span>
                ))
            }
          </p>
        </div>
      </article>
      <div className="div_shukran_tip_holder">
        <a className="shukran_tp_section_btn_a shukran_tp_section_btn shukran_three_tip" href={tiplink} target="_blank" rel="noreferrer">
          <button type="button" className="shukran_tp_section_btn transition-all hover:bg-[#48a3a8]">
            {tipbtn}
          </button>
        </a>
      </div>
    </main>
  );
}

ShukranTipThree.propTypes = {
  tiph11: PropTypes.string.isRequired,
  tipdes: PropTypes.string.isRequired,
  tipimg: PropTypes.string.isRequired,
  tipbtn: PropTypes.string.isRequired,
  tiplink: PropTypes.string.isRequired,
  des2: PropTypes.string.isRequired,
  tipdes2: PropTypes.string.isRequired,
  des3: PropTypes.string.isRequired, //eslint-disable-line
};

export default ShukranTipThree;
