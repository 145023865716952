import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import data from '../data.json';
import 'swiper/css/navigation';
import './css/Carouselgrid.css';

SwiperCore.use([Autoplay, Navigation]);

function Carouselgrid() {
  return (
    <article>
      <Swiper
        spaceBetween={30}
        loop
        centeredSlides
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: '.swiper-button-next_new',
          prevEl: '.swiper-button-prev_new',
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {data.joincommunity.map((dat) => (
          <div
            key={dat.id}
            className="text-center w-[80%] mx-auto flex flex-col gap-[1rem] "
          >
            <div id="joincommholderdiv">
              {dat.cards.map((da) => (
                <SwiperSlide
                  key={da.id}
                  className="bg-[#fff] rounded-[8px] px-[2.078rem] py-[4.875rem]"
                >
                  <figure className="flex justify-center ">
                    <img src={da.img} width={110} height={110} alt="icons" />
                  </figure>
                  <h3 className="text-[1.125rem] pt-[0.75rem] font-[600] font-['Nunito'] leading-[1.5] text-[#000]">
                    {da.h3tag}
                  </h3>
                  <p className="text-[#242321] pt-[0.75rem] text-[1.125rem] font-[500] font-['Nunito'] leading-[1.5]">
                    {da.des}
                  </p>
                </SwiperSlide>
              ))}
            </div>
          </div>
        ))}
        <div className="flex justify-between m-0">
          <div className="swiper-button-prev_new">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 57 57"
              fill="none"
            >
              <path
                d="M38.1444 51.1718C38.8123 50.5037 39.1875 49.5978 39.1875 48.6531C39.1875 47.7085 38.8123 46.8025 38.1444 46.1344L20.5101 28.5L38.1444 10.8657C38.7934 10.1938 39.1525 9.29387 39.1444 8.35979C39.1362 7.42571 38.7616 6.53219 38.1011 5.87168C37.4405 5.21116 36.547 4.8365 35.6129 4.82838C34.6789 4.82027 33.779 5.17934 33.1071 5.82828L12.954 25.9813C12.2862 26.6494 11.911 27.5554 11.911 28.5C11.911 29.4447 12.2862 30.3507 12.954 31.0187L33.1071 51.1718C33.7751 51.8397 34.6811 52.2148 35.6258 52.2148C36.5704 52.2148 37.4764 51.8397 38.1444 51.1718Z"
                fill="#64C8CE"
              />
            </svg>
          </div>
          <div className="swiper-button-next_new">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 57 57"
              fill="none"
            >
              <path
                d="M18.8556 51.1718C18.1877 50.5037 17.8125 49.5978 17.8125 48.6531C17.8125 47.7085 18.1877 46.8025 18.8556 46.1344L36.4899 28.5L18.8556 10.8657C18.2066 10.1938 17.8475 9.29387 17.8556 8.35979C17.8638 7.42571 18.2384 6.53219 18.8989 5.87168C19.5595 5.21116 20.453 4.8365 21.3871 4.82838C22.3211 4.82027 23.221 5.17934 23.8929 5.82828L44.046 25.9813C44.7138 26.6494 45.089 27.5554 45.089 28.5C45.089 29.4447 44.7138 30.3507 44.046 31.0187L23.8929 51.1718C23.2249 51.8397 22.3189 52.2148 21.3742 52.2148C20.4296 52.2148 19.5236 51.8397 18.8556 51.1718Z"
                fill="#64C8CE"
              />
            </svg>
          </div>
        </div>
      </Swiper>
    </article>
  );
}

export default Carouselgrid;
