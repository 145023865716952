import React from 'react';
import PropType from 'prop-types';
import './css/HowItwork.css';

function HowItWork({ hiw11, keypoints }) {
  return (
    <main className="howitwork">
      <h2 className="howitwork_h2">{hiw11}</h2>
      <section className="howitwork_section">
        {keypoints.map((point) => (
          <div key={point.id} className="howitwork_section_div">
            <img
              className="howitwork_section_div_img"
              src={point.pointimg}
              width="40"
              height="40"
              alt="icons"
            />
            <div className="howitwork_section_div_div">
              <b className="howitwork_section_div_div_b">{point.pointa}</b>
              <p className="howitwork_section_div_div_p">{point.pointb}</p>
            </div>
          </div>
        ))}
      </section>
    </main>
  );
}

HowItWork.propTypes = {
  hiw11: PropType.string.isRequired,
  keypoints: PropType.array.isRequired, //eslint-disable-line
};

export default HowItWork;
