import React from 'react';
import PropTypes from 'prop-types';
import BasicRating from './Rating';
import './css/Tipper.css';

function Tipper({
  // mainText,
  des,
  name,
  position,
  // link,
  img,
}) {
  return (
    <main className="tipper_main">
      <article className="tipper_main_article">
        <figure className="tipper_main_article_figure">
          <img src={img} alt="tipper images" className="tipper_main_article_figure_img" />
        </figure>
        <section className="tipper_main_article_section">
          {/* <h3 className="tipper_main_article_section_h3">
            {
              mainText
            }
          </h3> */}
          <div className="tipper_main_article_section_div">
            <span className="tipper_main_article_section_div_span">
              App Rating:
            </span>
            <BasicRating />
          </div>
          <p className="tipper_main_article_section_p">
            {des}
          </p>
          <div className="tipper_main_article_section_div1">
            <b className="tipper_main_article_section_div1_b">
              {name}
            </b>
            <span className="tipper_main_article_section_div1_span">
              {position}
            </span>
          </div>
          {/* <a href={link} target="_blank" className="tipper_main_article_section_div1_a" rel="noreferrer">
            {link}
          </a> */}
        </section>
      </article>
    </main>
  );
}

Tipper.propTypes = {
  // mainText: PropTypes.string.isRequired,
  des: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  // link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default Tipper;
