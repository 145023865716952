import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import Tipper from './subcomponents/Tipper';
import ServiceWorker from './subcomponents/ServiceWorker';
import Restaurant from './subcomponents/Restaurant';
import Partners from './subcomponents/Partners';
import './css/Testimonial.css';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const urls = [
  {
    id: 1,
    url: 'images/resources/ashaku.png',
  },

  {
    id: 2,
    url: 'images/resources/koroga.png',
  },

  {
    id: 3,
    url: 'images/resources/karen.png',
  },

  {
    id: 4,
    url: 'images/resources/bistro.png',
  },
];

const owner = [
  {
    id: 1,
    main_text: 'Woah!',
    des: 'Shukran has a very good concept that should be adopted by more if not all restaurants of Kenya because tipping  is a good culture that needs to be embraced by everyone who gets served by anyone when they go to other restaurants.',
    name: 'Jackie',
    position: 'Owner at Lapadella',
    link: 'https://dineandsash.restaurant',
    img: '/images/resources/owner5.webp',
  },
  {
    id: 2,
    main_text: 'Woah!',
    des: 'Tipping with Shukran is easy and convenient. Plus, when using your mobile money number with Shukran, they ensure your safety by keeping your number private. ',
    name: 'Ricky',
    position: 'Owner of Bistro Adreno',
    link: 'https://dinner.restaurant',
    img: '/images/resources/owner7.webp',
  },
];

const sw = [
  {
    id: 1,
    main_text: 'Woah!',
    des: 'The tips that I receive from the customers as a token has really impacted my life in different ways. Its helps me to meet my financial needs and support my family I encourage customers to continue tipping when they receive great service. Tipping changes lives!',
    name: 'Fenny',
    position: 'Service Worker',
    link: 'https://dinner.restaurant',
    img: '/images/resources/sw5.webp',
  },
  {
    id: 2,
    main_text: 'Woah!',
    des: 'Apart from receiving tips on the platform and directly saving it to my SACCO account, the SACCO also provides financial literacy classes that are a step forward into my financial freedom. I would like to encourage my fellow service workers to join Shukran Sacco today. This is a game changer',
    name: 'Clarence',
    position: 'Service Worker',
    link: 'https://dinner.restaurant',
    img: '/images/resources/sw6.webp',
  },
];

const tipper = [
  {
    id: 1,
    main_text: 'Woah!',
    des: 'This app is Awesome! It was so easy tipping with it! I Loooooooooove it! How  are people not already using it?! I have to spread the word about Shukran',
    name: 'Soni',
    position: 'Tipper',
    link: 'https://dinner.restaurant',
    img: '/images/resources/tipper4.webp',
  },
  {
    id: 2,
    main_text: 'Woah!',
    des: 'The thing about Shukran is you have to ask for somebody’s number but I like the privacy, I don’t want my name and number circulating all over the place',
    name: 'Dave',
    position: 'Tipper',
    link: 'https://dinner.restaurant',
    img: '/images/resources/tipper5.webp',
  },
];
/* eslint-disable */
function Testimonial() {
  return (
    <main className="main_testimonial">
      <h1 className="main_testimonial_h1s">Our testimonials</h1>
      <h2 className="main_testimonial_h2s">Tipper Testimonials</h2>
      <Swiper
        className="carousel-container"
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        >
        {tipper.map((data) => (
          <SwiperSlide
            key={data.id}
            style={{
            }}
          >
            <Tipper
              mainText={data.main_text}
              des={data.des}
              name={data.name}
              position={data.position}
              link={data.link}
              img={data.img}
            />
          </SwiperSlide>
        ))}
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination pag1" />
        </div>
        </Swiper>
        <h2 className="main_testimonial_h2s">
        Service Worker
        </h2>
        <Swiper
        className="carousel-container2 carousel-container3"
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        >
        {sw.map((data) => (
          <SwiperSlide
            key={data.id}
            style={{
            }}
          >
            <ServiceWorker
              des={data.des}
              name={data.name}
              position={data.position}
              img={data.img}
            />
          </SwiperSlide>
        ))}
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination pag2" />
        </div>
        </Swiper>
        <div className="our_partner_section_holder">
        <h2 className="main_testimonial_h2s">
          Our Partners
        </h2>
        <div className="partners_section">
          {
            urls.map((url) => (
              <Partners key={url.id} url={url.url} />
            ))
          }
          </div>
        </div>
        <h2 className="main_testimonial_h2s">
          Restaurant Owners
        </h2>
        <Swiper
        className="carousel-container2"
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        >
        {owner.map((data) => (
          <SwiperSlide
            key={data.id}
            style={{
            }}
          >
            <Restaurant
              des={data.des}
              name={data.name}
              position={data.position}
              img={data.img}
            />
          </SwiperSlide>
        ))}
        <div className="slider-controler sliderc-3">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline" />
          </div>
          <div className="swiper-button-next next_button_click slider-arrow">
            <ion-icon name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination pag3" />
        </div>
      </Swiper>
      <footer />
    </main>
  );
}

export default Testimonial;
