import React, { useState } from 'react';
import data from '../data.json';
import './css/StoryCom.css';

function StoryCom() {
  const [videoVisible, setVideoVisible] = useState(false);

  const toggleVideo = () => {
    setVideoVisible(!videoVisible);
  };
  return (
    <article className="bg-[#F5FFFE]">
      <div className="max-w-[1330px] mx-auto">
        {data.storycommunity.map((dat) => (
          <section
            key={dat.id}
            className="flex flex-col gap-[0rem] w-[80%] mx-auto "
          >
            <h2 className="leading-[1.5] mt-[1rem] uppercase text-[1.5rem] font-['NUnito'] text-center text-[#000] font-[600] md:text-[2rem]">
              {dat.head}
            </h2>
            <h3 className="leading-[1.3] font-['Nunito'] text-[1.3rem] text-[#000] text-center font-[400]">
              {dat.subhead}
            </h3>
            <div className="mt-[1rem]">
              {dat.card.map((dat) => (
                <div className="flex items-center bg-[#fff]  md:items-start" key={dat.id}>
                  <div className="flex flex-col w-[100%] gap-[1rem] py-[0.749rem] px-[0.321rem] bg-[#fff] min-[114px]:flex-[0.5]">
                    <div className="flex gap-0 flex-col md:gap-[2rem]">
                      <p className="m-0 font-[500] leading-[1.4] text-[1rem] text-[#000] font-['Nunito'] ">
                        {dat.name}
                      </p>
                      <figure className="flex items-center gap-[0.3rem]">
                        <img src={dat.icon} alt="suckranlog" width={20} />
                        <p className="text-[#000] leading-[1.3] text-[0.5rem] font-['Nunito'] md:text-[0.8rem]">
                          {dat.title}
                        </p>
                      </figure>
                    </div>
                    <q className="text-[0.875rem] font-['Nunito'] text-[#000] italic leading-[1.5] font-[500] md:text-[1rem] lg:text-[1.5rem] md:w-[70%]">
                      {dat.profile}
                    </q>
                  </div>
                  <div className="ml-[0.5rem] min-[114px]:flex-[0.5]">
                    {!videoVisible && (
                      <div className="thumbnail" onClick={toggleVideo}> {/* eslint-disable-line */}
                        <img
                          src="/images/resources/cover_photo.png"
                          alt="Video Thumbnail"
                          className="lg:max-w-[436px] lg:max-h-[472px]"
                        />
                      </div>
                    )}
                    {videoVisible && (
                      <div className="video-container min-[114px]:flex-[0.5]">
                        <iframe
                          width="100%"
                          height="100%"
                          className="md:w-[284px] md:h-[307px] lg:w-[436px] lg:h-[472px]"
                          src="https://www.youtube.com/embed/ea_KvMZG4cs?autoplay=1&loop=1&cc_load_policy=0" // Correct YouTube embed URL
                          title="Video"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>
    </article>
  );
}

export default StoryCom;
