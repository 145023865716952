/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { HashLink } from "react-router-hash-link";
import "./css/Footer.css";

function Footer() {
  const [windowSize, setWindowsize] = useState(window.innerWidth);
  const location = useLocation();
  const isfooter = location.pathname.startsWith("/community");
  useEffect(() => {
    function handleResize() {
      setWindowsize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <footer className="footer">
      <article className="footer_article">
        <div className="footer_article_divs">
          <figure className="footer_article_figure">
            <img
              src="images/resources/shukranwhitelogo.png"
              className="footer_article_figure_img"
              alt="Shukran logo"
            />
          </figure>
          <div className="footer_article_divs_app">
            <p className="footer_article_divs_app_p">Download our app:</p>
            <figure className="footer_article_divs_app_p_divs" id="appstore">
              <a
                href={
                  windowSize < 500
                    ? "https://bit.ly/DownloadShukran"
                    : "https://play.google.com/store/apps/details?id=ck.shukran.app&hl=en&gl=US"
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/resources/gpi.png" width={190} alt="apps" />
              </a>
              <a
                href={
                  windowSize < 500
                    ? "https://bit.ly/DownloadShukran"
                    : "https://apps.apple.com/us/app/shukran-platform/id6449225301"
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="images/resources/app.svg"
                  width="150"
                  height="150"
                  alt="apps"
                />
              </a>
            </figure>
          </div>
        </div>
        <nav className="footer_article_nav">
          <ul className="footer_article_nav_ul">
            <li className="footer_article_nav_ul_li">
              <h3 className="footer_article_nav_ul_li1_h3">JOIN US</h3>
              <a
                href="https://www.shukransacco.com"
                rel="noreferrer"
                target="_blank"
              >
                Join the Sacco
              </a>
              <HashLink to="/community#hero_header">
                Join Our Community
              </HashLink>
            </li>
            <li className="footer_article_nav_ul_li">
              <h3 className="footer_article_nav_ul_li3_h3">Resources</h3>
              <HashLink
                smooth
                className="footer_article_nav_ul_li3_link1"
                to="/testimonial#"
              >
                Testimonials
              </HashLink>
              <HashLink
                smooth
                className="footer_article_nav_ul_li3_link1"
                to="/faqs#"
              >
                FAQs
              </HashLink>
            </li>
            <li className="footer_article_nav_ul_li">
              <h3 className="footer_article_nav_ul_li4_h3">CONTACTS</h3>
              {isfooter ? (
                <a
                  href="mailto:info@shukran.co"
                  rel="noreferrer"
                  target="_blank"
                >
                  info@shukran.co
                </a>
              ) : (
                <a
                  href="mailto:hello@shukran.co"
                  rel="noreferrer"
                  target="_blank"
                >
                  info@shukran.co
                </a>
              )}
              {isfooter ? (
                <a href="tel:0758464586" rel="noreferrer" target="_blank">
                  +254758464586
                </a>
              ) : (
                <a href="tel:0758464586" rel="noreferrer" target="_blank">
                  +254 (758) 464 586
                </a>
              )}
              <div className="footer_article_nav_ul_li_div">
                <a
                  href="https://www.instagram.com/shukranke/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <InstagramIcon className="footer_insta_icon" />
                </a>
                <a
                  href="https://twitter.com/Shukran_Ke"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon className="footer_twitter_icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/shukranke/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <LinkedInIcon className="footer_linkedin_icon" />
                </a>
                <a
                  href="https://www.facebook.com/shukrankenya"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FacebookIcon className="footer_fb_icon" />
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <hr className="hr_footer" />
        <span className="copyright_footer">
          &copy; Shukran {new Date().getFullYear()}. All Right Reserved.
          <span className="modal_btn">
            {" "}
            <HashLink to="/terms" className="tulinks" smooth>
              Terms of Use
            </HashLink>
          </span>
          <span className="modal_btn">
            {" "}
            <HashLink to="/policy" href="/privacy" className="tulinks" smooth>
              Privacy Policy.
            </HashLink>
          </span>
        </span>
      </article>
    </footer>
  );
}

export default Footer;
